import gql from 'graphql-tag';

export const ANUNCIANTE_LOGADO = gql`
  query AnuncianteLogado {
    anuncianteLogado {
      id
      usuario {
        email
      }
      celular
      nomeFantasia
      telefone
      nomeRazao
      cnpj
      fotoPerfil
      endereco {
        cidade {
          latitude
          longitude
        }
      }
    }
  }
`;

export const TOTALIZADORES_MOTORISTAS = gql`
  query getMotoritasTotais {
    totalAnunciosFinalizadosDoAnunciante
    totalAnunciosAtivosDoAnunciante
    totalAnunciosPendentesDoAnunciante
  }
`;

export const FIND_ALL_ANUNCIOS_DA_CAMPANHA = gql`
  query findAnunciosByAnunciante(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    anuncios: findAnunciosByAnunciante(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        dataInstalacao
        dataInicioVeiculacao
        kmTotalPorMes
        quantidadeMeses
        kmRodado
        kmTotal
        kmRodadoDia
        campanha {
          id
          bannerCampanha
          titulo
          cidade {
            id
            nomeEUf
          }
        }
        veiculo {
          id
          anoFabricacao
          anoModelo
          placa
          modelo {
            marca {
              nome
            }
            nome
          }
        }
        motorista {
          id
          nome
          fotoPerfil
          veiculoAtual {
            id
            anoModelo
            anoFabricacao
            placa
            modelo {
              nome
              marca {
                nome
              }
            }
          }
        }
        localizacaoAtual {
          latitude
          longitude
          data
        }
        modeloAnuncio {
          frente
          laterais
          traseira
          interno
        }
      }
    }
  }
`;

export const FIND_ALL_VEICULOS_DA_CAMPANHA_SELECT = gql`
  query findAnunciosByAnunciante(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    motoristas: findAnunciosByAnunciante(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        motorista {
          id
          nome
        }
      }
    }
  }
`;

export const FIND_BY_ID_ANUNCIO = gql`
  query findByIdAnuncio($id: UUID) {
    findByIdAnuncio(id: $id) {
      id
      dataInstalacao
      dataInicioVeiculacao
      motorista {
        id
        fotoPerfil
        nome
      }
      diasCampanha
      dataLancamento
      anuncioBanner
      imagensDoVeiculo {
        id
        imagemDiagonalDireitaFrontal
        imagemDiagonalEsquerdaTraseira
        imagemOdometro
        imagemLateralEsquerda
        imagemLateralDireita
        imagemFrente
        imagemTraseira
      }
    }
  }
`;

export const TOTAIS_HOME = gql`
  query getTotaisHome {
    totalCidadesContratadas
    totalVeiculosContratados
  }
`;

export const TOTAIS_CAMPANHAS_DO_ANUNCIANTE = gql`
  query TotaisCampanhasDoAnunciante($cidadeId: UUID) {
    totalCampanhasAtivas: totalCampanhasAtivasDoAnunciante(cidade: $cidadeId)
    totalCampanhasFinalizadas: totalCampanhasFinalizadasDoAnunciante(
      cidade: $cidadeId
    )
  }
`;
export const TOTAIS_CAMPANHAS_DO_ANUNCIANTE_REAL = gql`
  query TotaisCampanhasDoAnunciante {
    totalCampanhasAtivas: totalCampanhasAtivasDoAnunciante
    totalCampanhasFinalizadas: totalCampanhasFinalizadasDoAnunciante
  }
`;

export const FIND_ALL_CAMPANHA_BY_ANUNCIANTE = gql`
  query findAllCampanhaByAnunciante(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllCampanhaByAnunciante(pageable: $pageable, searchDTO: $searchDTO) {
      last
      content {
        id
        titulo
        dataInicioVeiculacao
        dataInicioInstalacao
        diasPeriodo
        bannerCampanha
        quantidadeVeiculos
        quantidadeMeses
        kmTotal
        kmRodado: quantidadeDeKmsRodadosAteOMomento
        observacoesRelatorios
        situacao
        modeloAnuncio {
          nome
          interno
        }
        cidade {
          id
          nome
          uf
          latitude
          longitude
          mediaCorridas
        }
      }
    }
  }
`;

export const FIND_ALL_MOTORISTA_BY_ANUNCIANTE = gql`
  query findAllMotoristaByAnunciante(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllMotoristaByAnunciante(pageable: $pageable, searchDto: $searchDTO) {
      last
      content {
        id
        nome
        fotoPerfil
        anuncioAtivo {
          id
          dataInstalacao
          dataInicioVeiculacao
          dataRemocao
          kmTotal
          kmRodado
          kmRodadoDia
          kmRodadoMes
        }
        veiculoAtual {
          modelo {
            nome
            marca {
              nome
            }
          }
          placa
          anoModelo
          anoFabricacao
        }
      }
    }
  }
`;

export const FIND_ALL_ANUNCIO_IMAGEM_VEICULO = gql`
  query findAllAnuncioImagemVeiculo(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllAnuncioImagemVeiculo(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        imagemLateralEsquerda
        imagemLateralDireita
        imagemFrente
        imagemTraseira
        imagemInterna
        imagemDiagonalDireitaFrontal
        imagemDiagonalEsquerdaTraseira
        imagemOdometro
        valorOdometro
        data
        tipo
        motorista {
          id
        }
        veiculo {
          id
        }
      }
    }
  }
`;

export const FIND_ULTIMAS_LOCALIZACOES_DA_ANUNCIANTE_NA_CIDADE = gql`
  query findUltimasLocalizacaoDaAnunciateNaCidade($cidadeId: UUID) {
    findUltimasLocalizacaoDaAnunciateNaCidade(cidade: {id: $cidadeId}) {
      latitude
      longitude
      id
      data
      monitoramento {
        motorista {
          fotoPerfil
          nome
        }
      }
    }
  }
`;

export const FIND_ULTIMAS_LOCALIZACOES_DA_CAMPANHA = gql`
  query findUltimasLocalizacoesDaCampanha($campanhaId: UUID) {
    findUltimasLocalizacoesDaCampanha(campanha: {id: $campanhaId}) {
      latitude
      longitude
      id
      data
      monitoramento {
        motorista {
          fotoPerfil
          nome
        }
      }
    }
  }
`;

export const FIND_BY_ID_CAMPANHA = gql`
  query findByIdCampanha($id: UUID) {
    findByIdCampanha(id: $id) {
      id
      titulo
      dataCompra
      quantidadeMeses
      quantidadeVeiculos
      bannerCampanha
      dataInicioVeiculacao
      diasPeriodo
      observacoesRelatorios
      modeloAnuncio {
        nome
      }
      cidade {
        nome
        uf
        latitude
        longitude
      }
    }
  }
`;

export const TOTAL_CAMPANHA_POR_CIDADE = gql`
  query totalCampanhaPorCidadeDoAnunciante {
    totais: totalCampanhaPorCidadeDoAnunciante {
      id
      nome
      uf
      latitude
      longitude
      campanhas
      situacao
    }
  }
`;

export const FIND_ULTIMAS_LOCALIZACOES_MOTORISTA = gql`
  query findUltimasLocalizacoes($motorista: MotoristaInput) {
    findUltimasLocalizacoes(motorista: $motorista) {
      id
      latitude
      longitude
      data
      monitoramento {
        motorista {
          fotoPerfil
          nome
        }
      }
    }
  }
`;

export const FIND_ULTIMAS_LOCALIZACOES_MOTORISTA_CAMPANHA = gql`
  query findUltimasLocalizacoesByCampanha($anuncio: AnuncioInput) {
    localizacoes: findUltimasLocalizacoesByCampanha(anuncio: $anuncio) {
      id
      latitude
      longitude
      data
      monitoramento {
        motorista {
          fotoPerfil
          nome
        }
      }
    }
  }
`;

export const FIND_ALL_MOTORISTA = gql`
  query findAllMotorista(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllMotorista: findAllMotoristaByAnunciante(
      pageable: $pageable
      searchDto: $searchDTO
    ) {
      last
      content {
        id
        nome
      }
    }
  }
`;

export const FIND_ALL_CAMPANHA = gql`
  query findAllCampanha(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    campanhas: findAllCampanhaByAnunciante(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      content {
        id
        titulo
        bannerCampanha
        value: id
        label: titulo
        dataInicioVeiculacao
        quantidadeMeses
        quantidadeVeiculos
        diasPeriodo
        situacao
        totalKmRodado
        totalImpactos
        totalPassageiros
        anunciante {
          nomeFantasia
          nomeRazao
        }
        cidades {
          id
          cidade {
            nomeEUf
          }
        }
      }
    }
  }
`;

export const FIND_ALL_CIDADES_CAMPANHA = gql`
  query findAllCidadesByCampanha(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    cidades: findAllCidadesByCampanha(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      content {
        id
        quantidadeVeiculos
        totalKmRodado
        totalImpactos
        totalPassageiros
        campanha {
          id
          titulo
        }
        cidade {
          id
          nomeEUf
          densidadeDemografica
          populacao
          mediaCorridas
          latitude
          longitude
        }
      }
    }
  }
`;

export const GERAR_MAPA_DE_CALOR = gql`
  query gerarMapaDeCalorByAnunciante($campanhaCidade: CampanhaCidadeInput, $dataInicio: LocalDate, $dataFim: LocalDate, $pageable: PageableDTOInput) {
    gerarMapaDeCalor: gerarMapaDeCalorByAnunciante(campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim, pageable: $pageable) {
      last
      pageable {
        pageNumber
      }
      content {
        latitude
        longitude
        peso
      }
    }
  }
`;

export const KM_RODADO_PERIODOS_CAMPANHA = gql`
  query kmRodadoPeriodosByCampanha($campanha: CampanhaInput, $campanhaCidade: CampanhaCidadeInput, $intervalos: [IntervaloDTOInput]) {
    kmRodado: kmRodadoPeriodosByCampanha(campanha: $campanha, campanhaCidade: $campanhaCidade, intervalos: $intervalos)
  }
`;

export const TOTAIS_CAMPANHA_HOME = gql`
  query campanhaTotalKmRodado($campanha: CampanhaInput, $campanhaCidade: CampanhaCidadeInput, $dataInicio: LocalDate, $dataFim: LocalDate) {
    kmTotal: campanhaTotalKmRodado(campanha: $campanha, campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim)
    impactos: pessoasImpactadas(campanha: $campanha, campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim)
    corridasTotal: campanhaTotalCorridas(campanha: $campanha, campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim)
  }
`;

export const TOTAL_KM_RODADO_CAMPANHA = gql`
  query campanhaTotalKmRodado($campanha: CampanhaInput, $campanhaCidade: CampanhaCidadeInput, $dataInicio: LocalDate, $dataFim: LocalDate) {
    kmTotal: campanhaTotalKmRodado(campanha: $campanha, campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim)
    kmDiaSemana: kmRodadoPorDiaSemana(campanha: $campanha, campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim) {
      chave
      valor
    }
    kmPeriodoDia: kmRodadoPorPeriodoDia(campanha: $campanha, campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim) {
      chave
      valor
    }
  }
`;

export const KM_RODADO_HEATMAP_CAMPANHA = gql`
  query kmRodadoHeatmap($campanha: CampanhaInput, $campanhaCidade: CampanhaCidadeInput, $dataInicio: LocalDate, $dataFim: LocalDate) {
    kmRodadoHeatmap(campanha: $campanha, campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim) {
      valor
      diaSemana
      hora
    }
  }
`;

export const TOTAL_IMPACTOS_CAMPANHA = gql`
query campanhaTotalImpactos($campanha: CampanhaInput, $campanhaCidade: CampanhaCidadeInput, $dataInicio: LocalDate, $dataFim: LocalDate) {
  impactos: pessoasImpactadas(campanha: $campanha, campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim)
  custo: custoPorImpacto(campanha: $campanha, campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim)
  impactosDiaSemana: impactosPorDiaSemana(campanha: $campanha, campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim) {
    chave
    valor
  }
  impactosPeriodoDia: impactosPorPeriodoDia(campanha: $campanha, campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim) {
    chave
    valor
  }
}
`;

export const IMPACTOS_HEATMAP_CAMPANHA = gql`
  query impactosHeatmap($campanha: CampanhaInput, $campanhaCidade: CampanhaCidadeInput, $dataInicio: LocalDate, $dataFim: LocalDate) {
    impactosHeatmap(campanha: $campanha, campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim) {
      valor
      diaSemana
      hora
    }
  }
`;

export const CORRIDAS_PERIODOS_CAMPANHA = gql`
  query corridasPeriodosByCampanha($campanha: CampanhaInput, $campanhaCidade: CampanhaCidadeInput, $intervalos: [IntervaloDTOInput]) {
    corridas: corridasPeriodosByCampanha(campanha: $campanha, campanhaCidade: $campanhaCidade, intervalos: $intervalos)
  }
`;

export const TOTAL_CORRIDAS_CAMPANHA = gql`
  query campanhaTotalCorridas($campanha: CampanhaInput, $campanhaCidade: CampanhaCidadeInput, $dataInicio: LocalDate, $dataFim: LocalDate) {
    corridasTotal: campanhaTotalCorridas(campanha: $campanha, campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim)
    corridasDiaSemana: corridasPorDiaSemana(campanha: $campanha, campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim) {
      chave
      valor
    }
    corridasPeriodoDia: corridasPorPeriodoDia(campanha: $campanha, campanhaCidade: $campanhaCidade, dataInicio: $dataInicio, dataFim: $dataFim) {
      chave
      valor
    }
  }
`;

export const TOTAL_TRAFEGO_POR_PONTO_CAMPANHA = gql`
  query passagensPorPontoInteresse($campanha: CampanhaInput, $dataInicio: LocalDate, $dataFim: LocalDate) {
    passagens: passagensPorPontoInteresse(campanha: $campanha, dataInicio: $dataInicio, dataFim: $dataFim) {
      id
      nome
      latitude
      longitude
      quantidade
    }
  }
`;

export const TOTAL_KM_POR_PONTO_CAMPANHA = gql`
  query kmRodadoPorPontoInteresse($campanha: CampanhaInput, $dataInicio: LocalDate, $dataFim: LocalDate) {
    kmPontosInteresse: kmRodadoPorPontoInteresse(campanha: $campanha, dataInicio: $dataInicio, dataFim: $dataFim) {
      id
      nome
      latitude
      longitude
      kmRaio
      kmRodado
    }
  }
`;

export const KM_RODADO_PERIODO_CAMPANHA = gql`
  query kmRodadoPeriodoByCampanha($campanha: CampanhaInput, $inicio: LocalDate, $fim: LocalDate) {
    kmPeriodo: kmRodadoPeriodoByCampanha(campanha: $campanha, inicio: $inicio, fim: $fim)
  }
`;

export const IMPACTOS_PERIODOS_CAMPANHA = gql`
  query pessoasImpactadasPeriodos($campanha: CampanhaInput, $campanhaCidade: CampanhaCidadeInput,, $intervalos: [IntervaloDTOInput]) {
    impactos: pessoasImpactadasPeriodos(campanha: $campanha, campanhaCidade: $campanhaCidade, intervalos: $intervalos)
  }
`;

export const CUSTO_IMPACTO_PERIODOS_CAMPANHA = gql`
  query custoPorImpactoPeriodos($campanha: CampanhaInput, $intervalos: [IntervaloDTOInput]) {
    custoPorImpactoPeriodos(campanha: $campanha, intervalos: $intervalos)
  }
`;

export const FIND_ANUNCIO_DADOS_PERIODO = gql`
  query findAnuncioDadosPeriodoMotorista($anuncio: AnuncioInput) {
    dadosPeriodos: findAnuncioDadosPeriodoMotorista(anuncio: $anuncio) {
      dataInicio
      dataFinal
      kmRodado
    }

    kmRodadoDia: findQuilometroRodadoDia(anuncio: $anuncio) {
      quantidadeDeKms
    }
  }
`;

export const PRIMEIRO_ANUNCIO_CAMPANHA = gql`
query primeiroAnuncioDaCampanha($campanha: CampanhaInput) {
  anuncio: primeiroAnuncioDaCampanha(campanha: $campanha) {
    id
    dataInstalacao
    dataInicioVeiculacao
    dataConfirmacaoInstalacao
  }
}
`;

export const FIND_IMAGEM_INSTALACAO_ANUNCIO = gql`
  query findImagemInstalacaoVeiculoByAnuncio($anuncio: AnuncioInput) {
    imagemAnuncio: findImagemInstalacaoVeiculoByAnuncio(anuncio: $anuncio) {
      id
      imagemLateralEsquerda
      imagemLateralDireita
      imagemFrente
      imagemTraseira
      imagemDiagonalDireitaFrontal
      imagemDiagonalEsquerdaTraseira
      imagemOdometro
    }
  }
`;

export const FIND_ALL_IMAGENS_APROVADAS_ANUNCIO = gql`
  query findAllImagensVeiculoAprovadasByAnuncio($anuncio: AnuncioInput, $dataInicio: LocalDate, $dataFim: LocalDate, $dataInicioKm: LocalDate, $dataFimKm: LocalDate) {
    imagensAnuncio: findAllImagensVeiculoAprovadasByAnuncio(anuncio: $anuncio, dataInicio: $dataInicio, dataFim: $dataFim) {
      id
      imagemLateralEsquerda
      imagemLateralDireita
      imagemFrente
      imagemTraseira
      imagemDiagonalDireitaFrontal
      imagemDiagonalEsquerdaTraseira
      imagemInterna
      imagemOdometro
      valorOdometro
      tipo
      data
      latitude
      longitude
      motorista {
        id
      }
    }
    kmRodado: quilometroRodadoPeriodoAnuncio(anuncio: $anuncio, dataInicio: $dataInicioKm, dataFim: $dataFimKm)
  }
`;

export const KM_RODADO_DIA_DA_CAMPANHA = gql`
  query kmRodadoDiaDaCampanha($campanha: CampanhaInput, $dataInicio: LocalDate, $dataFim: LocalDate) {
    kmTotalDia: kmRodadoDiaDaCampanha(campanha: $campanha)
    kmTotal: campanhaTotalKmRodado(campanha: $campanha, dataInicio: $dataInicio, dataFim: $dataFim)
  }
`;