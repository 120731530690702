import {Close} from '@mui/icons-material';
import {Grid, Modal, Slide, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {getSidebarWidth} from '../../theme/mui-theme';
import {CondutorContainer} from '../condutor';
import MapaDetalhesMotorista from '../mapas/mapa-detalhes-motorista';

const DetalhesMotorista = ({openModal, onClose, anuncio = {}, ...props}) => {
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    setBanner(anuncio.campanha?.bannerCampanha);
  }, [anuncio]);

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      componentsProps={{
        backdrop: {
          sx: {
            background: 'rgb(0, 0, 0, 0.0)',
          },
        },
      }}
      {...props}>
      <Slide direction="left" in={openModal} appear>
        <Grid container sx={styles.modal}>
          <Grid container sx={{position: 'relative'}}>
            <Grid container item xs={3} sx={styles.motoristaContainer}>
              <Grid item xs={12} sx={styles.header} onClick={onClose}>
                <Close />
                <Typography paddingLeft={2}>Fechar</Typography>
              </Grid>
              <Grid>
                <CondutorContainer anuncio={anuncio} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MapaDetalhesMotorista
                anuncio={anuncio}
                campanhaCidade={anuncio.campanha}
              />
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
};

const styles = {
  modal: {
    width: () => `calc(100vw - ${getSidebarWidth()} - 8px) !important`,
    borderTopLeftRadius: '6px',
    zIndex: (theme) => theme.zIndex.modal,
    background: '#fff',
    position: 'absolute',
    right: '0px',
    height: (theme) => `calc(100vh - 8px - ${theme.toolbarHeight})`,
    top: (theme) => `calc(8px + ${theme.toolbarHeight})`,
    overflow: 'hidden'
  },
  motoristaContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '6px',
    backgroundColor: '#fff',
    alignContent: 'flex-start',
    boxShadow: '3px 3px 5px 0px rgba(0,0,0,0.36)',
    zIndex: 1,
  },
  header: {
    display: 'flex',
    padding: 1,
    height: '40px',
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow: '0px 3px 5px -3px #00000050',

    svg: {
      fontSize: 20,
    },
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    minHeight: 150,
    maxHeight: 150,
    boxShadow:
      'inset 0px 5px 6px -3px #00000050, inset 0px -5px 6px -3px #00000050',
  },
  imagePlaceholder: {
    color: '#fff',
    backgroundColor: '#d2d7e247',
    width: '100%',
    height: '150px',
    borderRadius: '6px',
    padding: '50px',
  },
};

export default DetalhesMotorista;
